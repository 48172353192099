import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import ButtonSolid from "../components/Button/ButtonSolid";
const Page = ({ data }) => {
  return (
    <Layout>
      <section className="relative bg-secondary-100 py-52">
        <div className="absolute left-0 top-0 h-full w-full">
          <img src={data.bg404.publicURL} alt="bg404" className="h-full w-full" />
        </div>
        <div className="absolute left-0 top-0 h-full w-full bg-black/50"></div>
        <div className="container relative">
          <div className="mx-auto max-w-[640px] text-center">
            <h1 className="text-white">Page Not Found</h1>
            <p className="text-lg text-white md:text-xl">
              We can't seem to locate the page you are looking for, double check
              your formula or use the button below.
            </p>
            <ButtonSolid altStyle={3} href="/" text="Go to Homepage" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    bg404: file(relativePath: { eq: "404/404-bg.gif" }) {
      publicURL
    }
  }
`;

export default Page;
